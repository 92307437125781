import React, { lazy, Suspense } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fallbackFadein: {
    visibility: 'hidden',
    animation: 'fadein 1.5s',
    animationFillMode: 'forwards',
    animationDelay: '0.5s' /* no spinner flickering for fast load times */
  },

  '@keyframes fadein': {
    'from': {
      visibility: 'visible',
      opacity: 0
    },
    'to': {
      visibility: 'visible',
      opacity: 1
    }
  },

  spin: {
    animation: 'spin 2s infinite linear'
  },

  "@keyframes spin": {
    '0%': {
      transform: 'rotate(0deg)',
    },
    "100%": {
      transform: "rotate(359deg)"
    },
    from: { transform: "rotate(0deg)" },
    to :{ transform: "rotate(360deg)" }
  },
  coverSpin: {
    visibility: 'hidden',
    animation: 'fadein 1.5s',
    animationFillMode: 'forwards',
    animationDelay: '0.5s'
    /* position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    display:block; */,
    "::after": {
      content: '',
      display: 'block',
      position: 'absolute',
      left: '48 %', 
      top: '40 %',
      width: '40px', 
      height: '40px',
      borderStyle:'solid',
      borderColor: 'black',
      borderTopColor: 'transparent',
      borderWidth: '4px',
      borderRadius: '50%',
      WebkitAnimation: 'spin .8s linear infinite',
      animation: 'spin .8s linear infinite'
    }
  },

  "@-webkit-keyframes spin": {
    "from": { WebkitTransform: "rotate(0deg)" },
    "to": { WebkitTransform: "rotate(360deg)" }
  },


}))

const Fallback = () => {
  const containerRef = React.useRef();
  const classes = useStyles()
  return (
    <div ref={containerRef}>
      <div className={classes.fallbackFadein}></div>
    </div>
  );
};

const loadable = (importFunc, { fallback = null } = { fallback: null }) => {
  const LazyComponent = lazy(importFunc);

  return props => (
    <Suspense fallback={<Fallback />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;