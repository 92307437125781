import { createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
    typography: {
      
        fontFamily: "'Roboto',Open Sans, sans-serif",
        
      },
})
export default theme;