import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from "./theme";
import CssBaseline from '@material-ui/core/CssBaseline';
import './index.css';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import loadable from './utils/loadable';


const AppLoadable = loadable(() => import('./App'));

ReactDOM.render(
  <React.StrictMode>
        <ThemeProvider theme={theme}>
          <CssBaseline />
    <BrowserRouter >
      <Switch>
        <Route path="/" component={AppLoadable} />
      </Switch>
    </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
reportWebVitals(console.log);